import { types as t } from 'mobx-state-tree';
import Validation from './validation';

const Pair = t
  .model('Pair', {
    key: t.maybeNull(t.string),
    value: t.maybeNull(t.string),
  })
  .volatile((self) => ({
    changed: false,
    validation: Validation.create(),
  }))
  .actions((self) => ({
    afterCreate() {
      self.validation.setFunc('key', () => {
        if (self.key == null) return 'required';
        if (self.key.trim().length === 0) return 'required';

        return '';
      });

      self.validation.setFunc('value', () => {
        if (self.value == null) return 'required';
        if (self.value.trim().length === 0) return 'required';

        return '';
      });
    },
    set(key, value) {
      if (value === '') value = null;
      self[key] = value;
      self.changed = true;
    },
    setUnChanged() {
      self.changed = false;
    },
  }))
  .views((self) => ({
    get Changed() {
      if (self.changed === true) return true;

      return false;
    },
  }));

const KeyValueCollection = t
  .model('KeyValueCollection', {
    collection: t.optional(t.array(Pair), []),
  })
  .volatile((self) => ({
    changed: false,
    validation: Validation.create(),
  }))
  .actions((self) => ({
    add(Key, Value) {
      if (Value === '') {
        Value = '-';
        self.changed = true;
      }
      if (self.collection.find((obj) => obj.key === Key) == null) {
        self.collection.push(Pair.create({ key: Key, value: Value }));
        self.changed = true;
      } else {
        self.collection.find((obj) => obj.key === Key).set('value', Value);
      }
    },
    _add(key, value) {
      return Promise.resolve(self.add(key, value));
    },
    remove(index) {
      self.collection.remove(self.collection[index]);
    },
    clear() {
      while (self.collection.length) self.collection.pop();
    },
    removeByKey(key) {
      self.collection.remove(self.collection.find((e) => e.key === key));
    },
    setKey(index, key) {
      if (self.collection[index].key !== key) {
        self.collection[index].key = key;
        self.changed = true;
      }
    },
    setValue(index, value) {
      if (self.collection[index].value !== value) {
        self.collection[index].value = value;
        self.changed = true;
      }
    },

    setUnChanged() {
      self.changed = false;
      self.collection.map((p) => p.setUnChanged());
    },
  }))
  .views((self) => ({
    find(Key) {
      return self.collection.find((obj) => obj.key === Key);
    },

    get Changed() {
      if (self.changed === true) return true;

      if (self.collection.find((p) => p.Changed === true)) return true;

      return false;
    },
  }));

export default t.optional(KeyValueCollection, {});
