import styled from 'styled-components'
import { Modal } from '@bootstrap-styled/v4'

export const ConfirmModal = styled(Modal)`
  .modal-dialog {
    max-width: 605px !important;
    background-color: transparent !important;
    margin: auto !important;
    height: 100%;
    display: flex;
    align-items: center;

    .modal-content {
      margin: 0 15px;
      width: 100%;
      text-align: left;
      border: 0;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25),
        0px 0px 100px rgba(0, 0, 0, 0.25);
      border-radius: 4px 4px 4px 4px;
      .modal-body {
        padding-right: 20px;
        padding-right: 20px;

        padding-bottom: 20px;
        padding-top: 0px;

        max-height: 80vh;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f0f0f0;
        }

        &::-webkit-scrollbar-thumb {
          background: #00a3e9;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #00a3e9;
        }

        @media (max-width: 575px) {
          padding: 30px 20px;
        }

        h3 {
          margin: 0 0 30px;
          font-weight: 600;
        }

        p {
          font-size: 18px;
          line-height: 1.5;
          margin-bottom: 30px;
          font-weight: 300;
        }

        .btn-wrapper {
          display: flex;
          justify-content: center;

          button {
            margin: 0 5px;
          }
        }
      }
    }
  }

  &.modal-dark {
    .modal-dialog {
      max-width: 1020px !important;

      .modal-body {
        background: #212529;
        text-align: left;

        label {
          color: #fff;
        }

        input {
          width: 100%;
        }
      }
    }

    svg {
      color: #fff !important;
    }
  }

  .modal-backdrop {
    background: #fff !important;

    &.show {
      opacity: 0.9 !important;
    }
  }
`

export const StackInputs = styled.div`
  display: flex;

  > div {
    margin-right: 2.5rem;
  }

  .input-group-addon {
    background: rgba(100, 102, 105, 0.4);
    border-color: rgba(100, 102, 105, 0.4) !important;
    color: #fff;
  }
`

export const CustomModalClose = styled.div`
  position: absolute;
  right: 30px;
  top: 29px;
`
