import React from 'react';
import logo from './logo.svg';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import useStore from './store/store';
import './App.css';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import Loadable from 'react-loadable';
import Loader from './components/Loader';
import { BootstrapProvider } from '@bootstrap-styled/provider';
import { Router, withRouter } from 'react-router';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mst-react-router';
import Menu from './components/Menu';

const Info = Loadable({
  loader: () => import('./components/Info.js'),
  loading() {
    return <Loader></Loader>;
  },
});

const Dashboard = Loadable({
  loader: () => import('./components/Dashboard.js'),
  loading() {
    return <Loader />;
  },
});

const Authorize = Loadable({
  loader: () => import('./components/Authorize.js'),
  loading() {
    return <Loader />;
  },
});

const Corelation = Loadable({
  loader: () => import('./components/Corelation.js'),
  loading() {
    return <Loader />;
  },
});

const Updates = Loadable({
  loader: () => import('./components/Updates.js'),
  loading() {
    return <Loader />;
  },
});

const view = {
  menu: <Menu></Menu>,
  corelation: <Corelation></Corelation>,
  updates: <Updates></Updates>,
  authorize: <Authorize></Authorize>,
  dashboard: <Dashboard></Dashboard>,
};

function App() {
  const [loaded, setLoaded] = useState(false);
  const { router } = useStore();
  const [hist, setHist] = useState(
    syncHistoryWithStore(createBrowserHistory(), router.router)
  );

  if (!loaded) {
    Promise.resolve(Amplify.configure(awsmobile)).then(() => {
      console.log('configuring amplify');

      setLoaded(true);
    });
  }

  if (!loaded) return <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      <Router history={hist}>
        <Main></Main>
      </Router>
    </React.Fragment>
  );

  if (!loaded) return <React.Fragment></React.Fragment>;
  return (
    <React.Fragment>
      <div style={{ margin: 5 }}>
        <BootstrapProvider>
          <Corelation></Corelation>
        </BootstrapProvider>
      </div>
    </React.Fragment>
  );
}

const Main = withRouter(
  observer(() => {
    return (
      <BootstrapProvider>
        <Menu />
        <View></View>
      </BootstrapProvider>
    );
  })
);

const View = observer(() => {
  const { router, user } = useStore();

  var pg = router.page;

  return (
    <React.Fragment>
      <h2>&nbsp;</h2>
      {pg !== 'authorize' && user.email == null ? (
        <Info></Info>
      ) : view[pg] ? (
        view[pg]
      ) : (
        ''
      )}
    </React.Fragment>
  );
});

export default App;
