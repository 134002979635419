import { types as t, fetch, flow } from 'mobx-state-tree';
import uuid from 'uuid';
import Currency from './currency';
import { executeQuery, deleteRecord, fetchRecord } from '../graphql';

const Target = t
  .model('Target', {
    reference: t.maybeNull(t.string),
    site: t.maybeNull(t.string),
    currency: t.maybeNull(Currency),
    color: t.maybeNull(t.string),
  })
  .volatile((self) => ({
    events: {},
  }))
  .actions((self) => ({
    afterCreate() {
      if (self.reference == null) self.reference = uuid.v4();
    },
    fetchPriceChangeEvents: flow(function* (year, month, day) {
      const snapshot = yield executeQuery(self.getQuery(year, month, day));
      if (snapshot) {
        self.events = snapshot.data.listRecords;
      }
    }),
    async getPriceChangeEvents(year, month, day) {
      var snapshot = await executeQuery(self.getQuery(year, month, day));
      debugger;
      if (snapshot) {
        var evts = [];
        self.setEvents(snapshot.data.listRecords);

        while (snapshot.data.listRecords.nextToken != null) {
          snapshot = await executeQuery(
            self.getQuery(year, month, day, snapshot.data.listRecords.nextToken)
          );
          self.addEvents(snapshot.data.listRecords.items);
        }
      }
    },
    setEvents(listRecords) {
      self.events = listRecords;
    },
    addEvents(items) {
      self.events.items.push(...items);
    },
  }))
  .views((self) => ({
    getQuery(year, month, day, nextToken) {
      if (nextToken === undefined) nextToken = null;
      var d = new Date();

      if (nextToken == null)
        return (
          'query{      listRecords(    partitionKey:"' +
          'event pricechange ' +
          self.site +
          ' ' +
          year +
          month +
          day +
          '", nextToken:' +
          nextToken +
          '     ) {  nextToken      items{ partitionKey, data, value, sortKey }      }    }'
        );

      return (
        'query{      listRecords(    partitionKey:"' +
        'event pricechange ' +
        self.site +
        ' ' +
        year +
        month +
        day +
        '", nextToken:"' +
        nextToken +
        '"     ) {  nextToken      items{ partitionKey, data, value, sortKey }      }    }'
      );
    },
  }));

export default t.optional(Target, {});
