import { types as t, flow, getSnapshot, applySnapshot } from 'mobx-state-tree'
import KeyValueCollection from './keyvalue'
import { fetchRecord, createRecord } from '../graphql'

const UserSettings = t
  .model('UserSettings', {
    userReference: t.maybeNull(t.string),
    settings: t.maybeNull(KeyValueCollection),
  })
  .volatile((self) => ({
    PartitionKey: 'usersettings',
  }))
  .actions((self) => ({
    set(key, value) {
      self[key] = value
    },
    fetch: flow(function* (reference) {
      const snapshot = yield fetchRecord({
        partitionKey: self.PartitionKey,
        sortKey: reference,
      })

      if (snapshot) {
        applySnapshot(self, snapshot)
      }
    }),
    save() {
      return Promise.resolve(
        createRecord({
          partitionKey: self.PartitionKey,
          sortKey: self.userReference,
          data: getSnapshot(self),
          value: null,
        })
      )
    },
  }))

  .views((self) => ({}))

export default t.optional(UserSettings, {})
