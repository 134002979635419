import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Auth } from 'aws-amplify';
import { applySnapshot } from 'mobx-state-tree';
import classnames from 'classnames';
import { FaUserCog } from 'react-icons/fa';
import {
  Img,
  NavbarToggler,
  Nav,
  NavItem,
  DropdownItem,
  Label,
} from '@bootstrap-styled/v4';

import {
  MenuLink,
  StyledNavbar,
  StyledNavbarBrand,
  StyledNavDropdown,
  LangDropdownToggle,
  LangDropdownMenu,
  FlagImg,
  StyledDropdownMenu,
  StyledDropdownToggle,
  StyledCollapse,
} from '../styles/Menu';
import User from '../store/user';
import DialogConfirm from './DialogConfirm';
import useStore from '../store/store';

import { ContentBox, ContentBoxTitle, ContentBoxBody } from '../styles/Show';

const Menu = () => {
  const { languages } = useStore();
  /*
  if (languages.languages.length === 0) {
    console.log('loading languages')
    languages.fetch().then(() => {
      setLoaded(true)
    })
  }
*/
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      document.getElementById('nav').classList.add('shrinked');
    } else {
      try {
        document.getElementById('nav').classList.remove('shrinked');
      } catch (e) {}
    }
  }

  return (
    <React.Fragment>
      <Interface></Interface>{' '}
    </React.Fragment>
  );
};
const Interface = observer(() => {
  const [dropdownStateLang, setDropdownStateLang] = useState(false);
  const [dropdownState, setDropdownState] = useState(false);
  const [navbarToggleState, setNavbarToggleState] = useState(false);
  const { user, router, languages, forex } = useStore();
  const [showSupport, setShowSupport] = useState(false);
  var navClasses = classnames({
    'absolute-top': true,
    'nav-transparent': false,
  });

  var togglerClasses = classnames({
    open: navbarToggleState,
  });

  return (
    <React.Fragment>
      <StyledNavbar className={navClasses} toggleable='sm' id='nav'>
        <StyledNavbarBrand>
          <Img
            className='brand-logo'
            onClick={() => {
              user.accessServer == null
                ? (document.location = 'https://elintell.com')
                : (document.location = user.accessServer);
            }}
            style={{ cursor: 'pointer', height: '35px' }}
            src={router.authority + '/images/logo/elintell.png'}
          ></Img>
        </StyledNavbarBrand>
        {user.email == null ? (
          ''
        ) : (
          <React.Fragment>
            <NavbarToggler
              className={togglerClasses}
              onClick={() => setNavbarToggleState(!navbarToggleState)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </NavbarToggler>
            <StyledCollapse navbar isOpen={navbarToggleState}>
              <Nav navbar className='ml-auto'>
                <LoginedMenu></LoginedMenu>
                <NavItem style={{ display: 'none' }}>
                  <MenuLink>
                    <FaUserCog onClick={() => setShowSupport(true)}></FaUserCog>
                  </MenuLink>
                </NavItem>
                <CurrencySelections></CurrencySelections>
                <StyledNavDropdown
                  style={{ display: 'none' }}
                  className='lang-menu'
                  isOpen={dropdownStateLang}
                  toggle={() => setDropdownStateLang(!dropdownStateLang)}
                >
                  <LangDropdownToggle nav caret>
                    <Label>
                      {user.settings.settings.find('currency') != null
                        ? JSON.parse(
                            user.settings.settings.find('currency').value
                          ).code
                        : 'EUR'}
                    </Label>
                  </LangDropdownToggle>
                  <LangDropdownMenu>
                    {forex.currencies.map((currency) => (
                      <Currency
                        setState={setDropdownStateLang}
                        key={currency.code}
                        currency={currency}
                      ></Currency>
                    ))}
                  </LangDropdownMenu>
                </StyledNavDropdown>
              </Nav>
            </StyledCollapse>
          </React.Fragment>
        )}
      </StyledNavbar>
    </React.Fragment>
  );
});

const Language = observer((props) => {
  const { router, user, langauge } = useStore();

  return (
    <React.Fragment>
      <DropdownItem
        onClick={(e) => {
          user.setLanguage(props.language.toJSON());
          props.setState(false);
          user.save();
        }}
      >
        <FlagImg
          src={props.language.image}
          title={props.language.abbr}
        ></FlagImg>
      </DropdownItem>
    </React.Fragment>
  );
});

const Currency = observer((props) => {
  const { router, user, langauge } = useStore();

  const changeCurrency = () => {
    Promise.resolve(
      user.settings.settings.add(
        'currency',
        JSON.stringify(props.currency.toJSON())
      )
    ).then(() => {
      user.settings.save();
    });
  };

  return (
    <React.Fragment>
      <DropdownItem
        onClick={(e) => {
          changeCurrency();
        }}
      >
        <Label>{props.currency.code}</Label>
      </DropdownItem>
    </React.Fragment>
  );
});

const LoginedMenu = observer((props) => {
  const { router, user } = useStore();
  const [dropdownState, setDropdownState] = useState(false);
  return (
    <React.Fragment>
      <StyledNavDropdown
        onMouseOver={() => setDropdownState(true)}
        onMouseOut={() => setDropdownState(false)}
        isOpen={dropdownState}
        toggle={() => setDropdownState(!dropdownState)}
      >
        <StyledDropdownToggle
          nav
          caret
          onClick={() => router.push('/dashboard')}
        >
          My Space
        </StyledDropdownToggle>
        <StyledDropdownMenu>
          <MenuItemCorelations></MenuItemCorelations>
          <MeniItemMarketUpdates></MeniItemMarketUpdates>
          <MenuItemVisualizations></MenuItemVisualizations>
        </StyledDropdownMenu>
      </StyledNavDropdown>
    </React.Fragment>
  );
});

const CurrencySelections = observer((props) => {
  const { router, user, forex } = useStore();
  const [dropdownState, setDropdownState] = useState(false);
  return (
    <React.Fragment>
      <StyledNavDropdown
        onMouseOver={() => setDropdownState(true)}
        onMouseOut={() => setDropdownState(false)}
        isOpen={dropdownState}
        toggle={() => setDropdownState(!dropdownState)}
      >
        <StyledDropdownToggle nav caret>
          {user.settings.settings.find('currency') != null
            ? JSON.parse(user.settings.settings.find('currency').value).code
            : 'EUR'}
        </StyledDropdownToggle>
        <StyledDropdownMenu>
          {forex.currencies.map((currency) => (
            <Currency key={currency.code} currency={currency}></Currency>
          ))}
        </StyledDropdownMenu>
      </StyledNavDropdown>
    </React.Fragment>
  );
});

const MenuItemCorelations = (props) => {
  const { router } = useStore();
  return (
    <React.Fragment>
      <DropdownItem
        className='dropdown-item-sm'
        onClick={() => router.push('/corelation')}
      >
        Corelations
      </DropdownItem>
    </React.Fragment>
  );
};
const MeniItemMarketUpdates = (props) => {
  const { router } = useStore();
  return (
    <React.Fragment>
      <DropdownItem
        className='dropdown-item-sm'
        onClick={() => router.push('/updates')}
      >
        Market updates
      </DropdownItem>
    </React.Fragment>
  );
};

const MenuItemVisualizations = (props) => {
  const { router } = useStore();
  return (
    <React.Fragment>
      <DropdownItem
        className='dropdown-item-sm'
        onClick={() => router.push('/updates')}
      >
        Visualization
      </DropdownItem>
    </React.Fragment>
  );
};
export default Menu;
