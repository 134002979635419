import { types as t, flow, applySnapshot, getSnapshot } from 'mobx-state-tree'
import { fetchRecord, createRecord } from '../graphql'
import Language from './language'

const Languages = t
  .model('Languages', {
    languages: t.optional(t.array(Language), [])
  })
  .actions(self => ({
    addLanguage(language) {
      self.languages.push(language)
    },
    updateName(reference, name) {
      self.languages.find(a => a.reference === reference).set('name', name)
    },
    updateAbbr(reference, abbr) {
      self.languages.find(a => a.reference === reference).set('abbr', abbr)
    },

    remove(reference) {
      self.languages.remove(self.languages.find(a => a.reference === reference))
    },
    fetch: flow(function*() {
      const data = {
        partitionKey: 'setup',
        sharding: false,
        sortKey: 'languages'
      }
      const snapshot = yield fetchRecord(data)
      if (snapshot) {
        console.log(snapshot)
        applySnapshot(self, snapshot)
      } else {
        self.legalForms.clear()
      }
    }),
    save() {
      self.save2({
        partitionKey: 'setup',
        sharding: false,
        sortKey: 'languages',
        data: self.snap
      })
    },
    save2({ partitionKey, sortKey, data, value, sharding = false }) {
      createRecord({
        partitionKey,
        sharding,
        sortKey,
        data,
        value
      })
    }
  }))
  .views(self => ({
    getCount() {
      return self.languages.length
    },
    getLanguage(abbr) {
      return self.languages.find(obj => obj.abbr === abbr)
    },
    get(language) {
      return self.languages.find(obj => obj.name === language.name)
    },
    get snap() {
      return getSnapshot(self)
    },
    exists(reference) {
      return self.languages.findIndex(a => a.reference === reference) > -1
        ? true
        : false
    }
  }))

export default t.optional(Languages, {})
