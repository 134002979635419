import { types as t, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import Item from './item';
import Items from './items';
import { RouterModel } from 'mst-react-router';
import Router from './router';
import Translation from './translation';
import Languages from './languages';
import Language from './language';
import Targets from './targets';
import Target from './target';
import Forex from './forex';
import Currency from './currency';
import DailyUpdates from './dailyupdates';
import User from './user';
import UserSettings from './usersettings';
import Currencies from './currencies';
import Configuration from './configuration';

const Store = t
  .model('Store', {
    version: '1.1.2',
    reference: t.maybeNull(t.string),
    item: Item,
    items: Items,
    router: Router,
    language: 'en',
    translations: t.map(Translation),
    languages: Languages,
    targets: Targets,
    forex: Forex,
    updates: t.map(DailyUpdates),
    user: User,
    currencies: Currencies,
    configuration: Configuration,
  })
  .actions((self) => ({
    afterCreate() {
      self.setTargets();
    },
    setLanguages() {
      var lang = Language.create();
      lang.set('name', 'english');
      lang.set('abbr', 'en');

      self.languages.addLanguage(lang);
      self.languages.save();
    },
    setTargets() {
      return;
      var eur = Currency.create({ code: 'EUR', name: 'Euro', rate: null });
      var hrk = Currency.create({
        code: 'HRK',
        name: 'Croatian kuna',
        rate: null,
      });

      self.targets.targets.push({
        site: 'musicmax.hr',
        currency: hrk.toJSON(),
        color: '#ED1C24',
      });
      self.targets.targets.push({
        site: 'hartman.si',
        currency: eur.toJSON(),
        color: '#1C3D82',
      });
      self.targets.targets.push({
        site: 'euro-unit.com',
        currency: hrk.toJSON(),
        color: '#E1B923',
      });
      self.targets.targets.push({
        site: 'thomann.de',
        currency: eur.toJSON(),
        color: '#3B3B3B',
      });
      self.targets.targets.push({
        site: 'muziker.si',
        currency: eur.toJSON(),
        color: '#78B1DE',
      });
    },
    setUser() {},
  }));
export const store = Store.create({ router: { router: RouterModel.create() } });

const storeContext = createContext(store);

//if (process.env.NODE_ENV !== 'production') {
// for debug
window.store = store;

export default () => useContext(storeContext);
