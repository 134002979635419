// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://v5ihxrl7gvezvdwxuwhzskqhrm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qe6rjfgq4req3laae53gpmkzq4",
    "aws_content_delivery_bucket": "elintell-mi-20200214110649-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d1vmmdawr26xg3.cloudfront.net"
};


export default awsmobile;
