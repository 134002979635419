import { types as t, getSnapshot, applySnapshot, flow } from 'mobx-state-tree'
import uuid from 'uuid'
import { createHistory, fetchRecord } from '../graphql'
import Item from './item'
import { FaSignInAlt } from 'react-icons/fa'

const DailyUpdates = t
  .model('DailyUpdates', {
    account: t.maybeNull(t.string),
    site: t.maybeNull(t.string),
    year: t.maybeNull(t.integer),
    month: t.maybeNull(t.integer),
    day: t.maybeNull(t.integer),
    items: t.optional(t.array(Item), []),
  })
  .actions((self) => ({
    fetch: flow(function* (accountReference, site, year, month, day) {
      const data = {
        partitionKey: 'dailyupdates ' + accountReference,
        sortKey: site + ' ' + year + ' ' + month + ' ' + day,
      }

      const snapshot = yield fetchRecord(data)

      if (snapshot) {
        applySnapshot(self, snapshot)
      }
    }),
    set(key, value) {
      self[key] = value
    },
  }))

export default t.optional(DailyUpdates, {})
