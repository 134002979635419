import { types as t, getSnapshot } from 'mobx-state-tree'
import uuid from 'uuid'
import { File } from './file'
const Language = t
  .model('Language', {
    reference: t.maybeNull(t.string),
    name: t.maybeNull(t.string),
    abbr: t.maybeNull(t.string),
    flag: t.maybeNull(File)
  })
  .actions(self => ({
    afterAttach() {
      if (self.reference === null) self.reference = uuid.v4()
      if (self.flag == null) self.flag = File.create()
    },
    afterCreate() {
      if (self.reference === null) self.reference = uuid.v4()
      if (self.flag == null) self.flag = File.create()
    },
    set(field, value) {
      self[field] = value
    },
    setFlag(filesClip) {
      if (filesClip.files.length === 0) {
        self.flag = File.create()
      } else {
        self.flag = filesClip.files[0].toJSON()
      }
    }
  }))
  .views(self => ({
    get image() {
      if (self.flag == null) return '/images/language/' + self.abbr + '.svg'
      if (self.flag.url) {
        return self.flag.link
      } else {
        return '/images/language/' + self.abbr + '.svg'
      }
    },
    get snap() {
      return getSnapshot(self)
    },
    get(key) {
      return self[key] != null ? self[key] : ''
    }
  }))

export default t.optional(Language, {})
