import { types as t } from 'mobx-state-tree';
import uuid from 'uuid';
import Language from './language';
import UserSettings from './usersettings';
import Account from './account';

const User = t
  .model('User', {
    reference: t.maybeNull(t.string),
    language: t.maybeNull(Language),
    accountReference: t.maybeNull(t.string),
    email: t.maybeNull(t.string),
    language: t.maybeNull(t.string),
    accessServer: t.maybeNull(t.string),
  })
  .volatile((self) => ({
    settings: UserSettings.create(),
    account: Account.create(),
    appsettings: t.maybeNull(t.string),
  }))
  .actions((self) => ({
    afterCreate() {
      if (self.reference == null) self.reference = uuid.v4();
      if (self.account == null)
        self.account = '6E63FDFE459F43F8A0526B336AB7DF22';
    },
    setAppSettings(settings) {
      self.appsettings = JSON.parse(settings);
    },
    set(key, value) {
      self[key] = value;
      try {
        if (key === 'reference') self.settings.set('userReference', value);
      } catch (e) {
        alert(e);
      }
    },
  }))
  .views((self) => ({
    get Authorized() {
      return true;
    },
    async hasAppSetting(tag) {
      return self.appsettings.find((s) =>
        s.ToggleS.find((as) => as.Name.toLowerCase() === tag.toLowerCase())
      )
        ? true
        : false;
    },
  }));

export default t.optional(User, {});
