import { types as t, getSnapshot, flow, applySnapshot } from 'mobx-state-tree'
import Target from './target'
import { createRecord, fetchRecord } from '../graphql'
const Targets = t
  .model('Targets', {
    accountReference: t.maybeNull(t.string),
    targets: t.optional(t.array(Target), []),
  })
  .volatile((self) => ({
    PartitionKey: 'targets',
  }))
  .actions((self) => ({
    addTarget(target) {
      self.targets.push(target)
    },
    set(key, value) {
      self[key] = value
    },
    save() {
      return Promise.resolve(
        createRecord({
          partitionKey: self.PartitionKey,
          sortKey: self.accountReference,
          data: getSnapshot(self),
          value: null,
        })
      )
    },
    fetch: flow(function* (accountReference) {
      var data = {
        partitionKey: self.PartitionKey,
        sortKey: accountReference,
      }

      var snapshot = yield fetchRecord(data)

      if (snapshot) {
        applySnapshot(self, snapshot)
      }
    }),
  }))
  .views((self) => ({
    async getJSONDataTemplate(site) {
      var tmp = '{"name":"pricing", "' + site + '":0'
      await self.targets.map((t) => (tmp += ',"' + t.site + '":0'))
      tmp += '}'

      return JSON.parse(tmp)
    },
  }))

export default t.optional(Targets, {})
