import { types as t, getRoot } from 'mobx-state-tree'
import { RouterModel } from 'mst-react-router'
import base65536 from 'base65536'
import queryString from 'query-string'
const model = {
  router: RouterModel,
}

const actions = (r) => ({
  push(pathname) {
    // let path = pathname
    if (r.root) r.router.push(pathname)
  },
  setHash(key, value) {
    let data = {}
    if (typeof r.hashData === 'object') {
      data = r.hashData
    }

    // prevent double push to history
    if (data[key] === value) return

    data[key] = value
  },
  resetHash(object) {
    let path = '/' + (r.path || '.')
    if (object) {
      const stringified = JSON.stringify(object || null)
      const encoded = base65536.encode(Buffer.from(stringified))
      path = path + '/#/' + encoded
    }
    r.router.push(path)
  },
})

const views = (self) => ({
  get path() {
    return self.router.location.pathname.replace(/\/$/, '').substr(1)
  },
  get page() {
    var pg = self.path.split('/')[0]

    //var fPage = self.root.pages.getPage(pg)

    return pg == null ? '' : pg
  },
  getQuery(name) {
    const values = queryString.parse(self.path)
    console.log(values)
  },
  get params() {
    var ar = self.path.split('/')
    ar.shift()
    ar.shift()
    return ar
  },
  get action() {
    var ar = self.path.split('/')
    ar.shift()

    return ar[0]
  },
  get authority() {
    return window.location.protocol + '//' + window.location.host
  },
  get hash() {
    return decodeURI(self.router.location.hash.substr(2))
  },
  getAction(pgUrl) {
    var ar = pgUrl.split('/')
    ar.shift()
    return ar[1]
  },
  get hashData() {
    let obj = undefined
    try {
      const data = base65536.decode(self.hash, true)
      const dataView = new DataView(data)
      const decoder = new TextDecoder('utf8')
      const stringified = decoder.decode(dataView)
      obj = JSON.parse(stringified)
    } catch {}
    return obj
  },
  get root() {
    return getRoot(self)
  },
})

export default t.model(model).actions(actions).views(views)
