import { types as t, applySnapshot, flow } from 'mobx-state-tree'
import Currency from './currency'
import { executeQuery, deleteRecord, fetchRecord } from '../graphql'
const Forex = t
  .model('Forex', {
    stamp: t.maybeNull(t.Date),
    bank: t.maybeNull(t.string),
    currency: t.maybeNull(Currency),
    currencies: t.optional(t.array(Currency), []),
  })
  .volatile((self) => ({
    PartitionKey: 'forex',
  }))
  .actions((self) => ({
    afterCreate() {
      self.currency = { code: 'EUR', name: 'Euro', rate: 1 }
      self.currencies.push({ code: 'HRK', name: 'Croatian kuna', rate: 7.61 })
      self.currencies.push({ code: 'EUR', name: 'Euro', rate: 1 })
    },
    set(key, value) {
      self[key] = value
    },
    fetch: flow(function* () {
      const data = {
        partitionKey: self.PartitionKey,
        sortKey: self.SortKey(),
      }
      const snapshot = yield fetchRecord(data)
      if (snapshot) {
        applySnapshot(self, snapshot)
      }
    }),
  }))
  .views((self) => ({
    get SortKey() {
      return 'forex ' + self.bank + ' ' + self.Date
    },
    Convert(currency, value) {
      if (currency.code === self.currency.code) return value

      return self.currencies
        .find((c) => c.code === currency.code)
        .Convert(value)
    },
    ConvertTo(currency, value) {
      try {
        return self.currencies
          .find((c) => c.code === currency.code)
          .ConvertTo(value)
      } catch (e) {}
      return 0
    },
  }))

export default t.optional(Forex, {})
