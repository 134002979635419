import { types as t } from 'mobx-state-tree'
import uuid from 'uuid'

export const SubFolder = t
  .model('SubFolder', {
    reference: t.maybeNull(t.string),
    name: t.maybeNull(t.string)
  })
  .actions(self => ({
    afterAttach() {
      if (self.reference === null || self.reference === '')
        self.reference = uuid.v4()
    },
    afterCreate() {
      if (self.reference === null || self.reference === '')
        self.reference = uuid.v4()
    },
    set(key, value) {
      self[key] = value
    }
  }))
