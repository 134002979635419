import { types as t, unprotect } from 'mobx-state-tree'
import uuid from 'uuid'

const Pair = t
  .model('Pair', {
    key: t.maybeNull(t.string),
    value: t.maybeNull(t.string)
  })
  .volatile(self => ({
    func: () => {}
  }))
  .actions(self => ({
    set(key, value) {
      self[key] = value
    },
    run() {
      self._run().then(res => {
        self.set('value', res)
      })
    },
    _run() {
      return Promise.resolve(
        self.func(val => {
          self.set('value', val)
        })
      )
    }
  }))
  .views(self => ({}))

const KeyValueCollection = t
  .model('KeyValueCollection', {
    collection: t.optional(t.array(Pair), [])
  })
  .actions(self => ({
    add(Key, Value) {
      if (self.collection.find(obj => obj.key === Key) == null)
        self.collection.push(Pair.create({ key: Key, value: Value }))
    },
    remove(index) {
      self.collection.remove(self.collection[index])
    },
    addPair(pair) {
      self.collection.push(pair)
    }
  }))
  .views(self => ({
    find(Key) {
      return self.collection.find(obj => obj.key === Key)
    }
  }))

const Validation = t
  .model('Validation', {
    reference: t.maybeNull(t.string),
    collection: t.maybeNull(KeyValueCollection)
  })
  .actions(self => ({
    afterCreate() {
      if (self.reference === null) self.reference = uuid.v4()
      if (self.collection === null)
        self.collection = KeyValueCollection.create()
    },
    setError(field, err) {
      if (self.collection.find(field)) {
        self.collection.find(field).set('value', err)
      } else {
        self.collection.add(field, err)
      }
    },
    test(field) {
      if (self.collection.find(field)) self.collection.find(field).run()
    },
    setFunc(field, func) {
      if (self.collection.find(field)) {
        self.collection.find(field).set('func', func)
      } else {
        var pair = Pair.create()
        unprotect(pair)
        pair.key = field
        pair.func = func
        pair.value = ''
        self.collection.addPair(pair)
      }
    }
  }))
  .views(self => ({
    getError(field) {
      if (self.collection.find(field)) {
        return self.collection.find(field).value
      } else return ''
    },
    get hasAnyError() {
      return self.collection.collection.find(f => f.value.trim().length > 0)
        ? true
        : false
    },
    tests(fields) {
      self.executeTests(fields).then(() => {
        console.log('tests executed')
        self.probeTests(fields).then(res => {
          console.log('probed' + res)
          return Promise.resolve(res)
        })
      })
    },
    executeAllAvailable() {
      return Promise.resolve(self.collection.collection.map(pair => pair.run()))
    },
    executeTests(fields) {
      return Promise.resolve(fields.map(field => self.test(field)))
    },
    executeTest(field) {
      return Promise.resolve(self.test(field))
    },
    probeAllTests() {
      return Promise.resolve(
        self.collection.collection.find(p =>
          p.value != null ? p.value.length > 0 : {}
        )
          ? false
          : true
      )
    },
    logProbe() {
      console.log(self.collection.collection.find(p => p.value.length > 0))
    },
    probeTests(fields) {
      // return Promise.resolve(true)
      return Promise.resolve(self._probeTests(fields))
    },
    _probeTests(fields) {
      var passed = true
      for (var i = 0; i < fields.length; i++) {
        if (self._probeTest(fields[i]) === false) {
          console.log(
            fields[i] + 'came' + self.collection.find(fields[i]).value
          )
          return Promise.resolve(false)
        }
      }
      return Promise.resolve(true)
    },
    _probeTest(field) {
      return self.collection.find(field)
        ? self.collection.find(field).value === ''
          ? true
          : false
        : true
    }
  }))

export default t.optional(Validation, {})
