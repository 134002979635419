import { types as t, flow, applySnapshot, unprotect } from 'mobx-state-tree';
import Item from './item';
import uuid from 'uuid';
import { executeQuery, deleteRecord } from '../graphql';
import Configuration from './configuration';
import configuration from './configuration';

const Items = t
  .model('Items', {
    reference: t.maybeNull(t.string),
    items: t.optional(t.array(Item), []),
  })
  .volatile((self) => ({
    changed: false,
    site: '',
    limit: 10,
    token: '',
    lastKeyword: '',
    tokens: [],
  }))
  .actions((self) => ({
    afterCreate() {
      if (self.reference == null) self.reference = uuid.v4();
    },
    setSite(site) {
      self.site = site;
    },
    setSiteAndLimit(site, limit) {
      self.site = site;
      self.limit = limit;
    },
    setLimit(limit) {
      self.limit = limit;
    },
    setToken(token) {
      self.token = token;
      if (self.tokens.findIndex((t) => t === token) < 0)
        self.tokens.push(token);
    },
    set(key, value) {
      self[key] = value;
    },
    clear() {
      while (self.items.length) self.items.pop();
    },

    deleteLink() {
      return Promise.resolve(deleteRecord({ partitionKey: 'link' }));
    },
    list: flow(function* (token, site) {
      self.changed = false;

      //while (self.items.length) self.items.pop()
      //console.log(self.query(keyword, self.site, self.limit))
      self.clear();
      const snapshot = yield executeQuery(self.listQuery(token, site));
      if (snapshot) {
        var n = 0;
        self.token = snapshot.data.listRecords.nextToken;

        while (snapshot.data.listRecords.items[n]) {
          if (snapshot.data.listRecords.items[n].data != null) {
            var _item = Item.create();

            applySnapshot(
              _item,
              JSON.parse(snapshot.data.listRecords.items[n].data)
            );

            self.items.push(_item);
          }

          //self.addResult(_result)
          n++;
        }
      }
    }),

    resetTokens() {
      self.tokens = [];
      self.tokens.push('');
    },
    addItem(item) {
      self.items.push(item);
    },
    setKeyword(kw) {
      self.lastKeyword = kw;
    },
    async search(keyword, site, limit, token) {
      // if (keyword.length < 3) return

      if (token == null) token = '';

      if (site != null) {
        self.site = site;
      }
      if (keyword !== self.lastKeyword) {
        self.resetTokens();
        self.lastKeyword = keyword;
      }
      if (limit != null) {
        self.limit = limit;
      }
      self.changed = false;
      self.clear();
      var config = Configuration.create();
      var res = await fetch(
        config.api +
          '?site=' +
          self.site +
          '&limit=' +
          self.limit +
          '&token=' +
          token +
          '&keyword=' +
          keyword.split(' ')
      );
      if (res) {
        var txt = await res.text();
        var result = JSON.parse(txt);
        self.setToken(result.token);
        var idata = result.items;

        var i = 0;
        while (idata[i]) {
          var _item = Item.create();
          unprotect(_item);
          applySnapshot(_item, idata[i]);
          self.addItem(_item);
          i++;
        }
      }
      return true;
    },
    fetch: flow(function* (keyword, site, limit) {
      if (site != null) {
        self.site = site;
      }
      if (limit != null) {
        self.limit = limit;
      }

      self.changed = false;
      self.clear();
      //while (self.items.length) self.items.pop()
      //console.log(self.query(keyword, self.site, self.limit))
      console.log(self.query(keyword, self.site, self.limit));
      if (keyword.length > 0) {
        const snapshot = yield executeQuery(
          self.query(keyword, self.site, self.limit)
        );
        if (snapshot) {
          var n = 0;

          while (snapshot.data.listRecords.items[n]) {
            if (snapshot.data.listRecords.items[n].data != null) {
              var _item = Item.create();

              applySnapshot(
                _item,
                JSON.parse(snapshot.data.listRecords.items[n].data)
              );
              if (
                snapshot.data.listRecords.items[n].sortKey.indexOf(_item.link) >
                -1
              )
                if (
                  self.items.find((i) => i.reference === _item.reference) ==
                  null
                )
                  self.items.push(_item);
            }

            //self.addResult(_result)
            n++;
          }
        }
      }
    }),
  }))
  .views((self) => ({
    get(key) {
      return self[key] != null ? self[key] : '';
    },
    getTokens() {
      return self.tokens;
    },
    query(keyword, site, limit) {
      var words = keyword.trim().split(' ');

      var qry =
        'query{ listRecords(limit: 10000, filter:{ and: {value: {contains: "' +
        words[0] +
        '"},';

      words.map(
        (word) => (qry += 'and: {value: {contains: "' + word + '"} },')
      );

      qry +=
        ' }}, sortKey: {beginsWith: "item ' +
        site +
        ' http"}, partitionKey: "item") {    nextToken    items {      data      value      partitionKey      sortKey    }  } }';

      return qry;
    },
    listQuery(token, site) {
      if (token != null) token = '"' + token + '"';
      else token = 'null';
      var qry =
        'query{      listRecords(partitionKey: "item" sortKey: { beginsWith: "item ' +
        site +
        ' http" } limit: 10 nextToken: ' +
        token +
        ') {        nextToken, items{ data }      }    }';

      return qry;
    },
  }));

export default t.optional(Items, {});
