import { types as t, getSnapshot } from 'mobx-state-tree'
import uuid from 'uuid'
import { createRecord, deleteRecord } from '../graphql'
import { SubFolder } from './subfolder'

export const File = t
  .model('File', {
    reference: t.maybeNull(t.string),
    folder: t.maybeNull(SubFolder),
    name: t.maybeNull(t.string),
    url: t.maybeNull(t.string),
    description: t.maybeNull(t.string),
    author: t.maybeNull(t.string),
    consent: t.maybeNull(t.boolean)
  })
  .volatile(self => ({
    PartitionKey: 'file',
    Server: 'https://userrepo.s3.eu-central-1.amazonaws.com/public/',
    UserReference: ''
  }))
  .actions(self => ({
    afterCreate() {
      if (self.reference == null || self.reference === '')
        self.reference = uuid.v4()
    },
    set(key, value) {
      self[key] = value
    },
    save() {
      return Promise.resolve(
        createRecord({
          partitionKey: self.PartitionKey,
          sharding: false,
          sortKey: self.SortKey,
          data: self.snap,
          value: null
        })
      )
    },
    delete() {
      return Promise.resolve(
        deleteRecord({
          partitionKey: self.PartitionKey,
          sortKey: self.SortKey
        })
      )
    }
  }))
  .views(self => ({
    get SortKey() {
      return (
        self.UserReference + '_' + self.folder.reference + '_' + self.reference
      )
    },
    get snap() {
      return getSnapshot(self)
    },
    get link() {
      try {
        var u = self.url
        while (u.indexOf(' ') > -1) {
          u = u.replace(' ', '+')
        }
        return self.Server + u
      } catch (e) {
        return ''
      }
    },
    get(key) {
      return self[key]
    },
    get extension() {
      if (self.name.indexOf('.') < 0) return ''
      return self.name.substring(self.name.lastIndexOf('.')).replace('.', '')
    },
    get isImage() {
      if ('jpg,png,gif'.indexOf(self.extension) > -1) return true

      return false
    },
    get isPdf() {
      if ('pdf'.indexOf(self.extension) > -1) return true

      return false
    },
    get fullName() {
      return self.UserReference + '/' + self.folder.reference + '/' + self.name
    }
  }))
