import { types as t } from 'mobx-state-tree'
import AccountSettings from './accountsettings'

const Account = t
  .model('Account', {
    reference: t.maybeNull(t.string),
  })
  .volatile((self) => ({
    settings: AccountSettings.create(),
  }))

export default t.optional(Account, {})
