import styled from 'styled-components'
import {
  Navbar,
  NavbarBrand,
  DropdownMenu,
  NavDropdown,
  DropdownToggle,
  Img,
  Collapse
} from '@bootstrap-styled/v4'

export const MenuLink = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  color: #000;
  font-size: 1rem;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  display: block;
  border: 1px solid transparent;

  @media (min-width: 767px) {
    margin: 0 1rem;
  }

  &.outline {
    color: #ffd336;
    border-color: #ffd336;

    &:hover {
      background-color: #ffd336;
      color: #000;
    }
  }
`

export const StyledNavbar = styled(Navbar)`
  &.navbar {
    padding: 1.25rem 3.125rem;
    transition: padding 0.3s ease-in-out;

    @media (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.absolute-top {
    border-bottom: 1px solid #e9e9e9;
    box-shadow: 0px 1px 8px -3px rgba(199, 199, 199, 1);
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;

    &.shrinked {
      background: #fff;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      button.navbar-toggler {
        top: 20px;
      }
    }
  }

  &.nav-transparent {
    background: transparent;
  }
`

export const StyledCollapse = styled(Collapse)`
  ul {
    @media (max-width: 767px) {
      margin-top: 30px;
    }

    li {
      @media (max-width: 767px) {
        margin-left: 0;
        display: block;
      }

      + li {
        @media (max-width: 767px) {
          margin-top: 15px;
        }
      }
    }
  }
`

export const StyledNavbarBrand = styled(NavbarBrand)`
  &.navbar-brand {
    min-width: 230px;
    padding: 0;

    @media (max-width: 575px) {
      min-width: 250px;
      max-width: 250px;
    }

    img {
      max-width: 100%;
    }
  }
`

export const StyledNavDropdown = styled(NavDropdown)`
  display: flex;
  margin-left: 1.25rem;

  &.show {
    .nav-link {
      background: #fff;
      color: #27292a !important;

      &.text-primary {
        &:hover,
        &:focus {
          color: #27292a !important;
        }
      }
    }
  }

  &.lang-menu {
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  a.nav-link {
    &:focus {
      color: #fff !important;
    }
  }
`

export const LangDropdownToggle = styled(DropdownToggle)`
  &.nav-link {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0.625rem 0.75rem !important;
    border-radius: 3px;

    &:after {
      border-top-color: #5fb798;
      margin-left: 0.6rem;
    }
  }
`

export const StyledDropdownToggle = styled(LangDropdownToggle)`
  &.nav-link {
    padding: 0.4rem 0.75rem !important;
    color: #000000 !important;
    background: transparent;
    color: #000 !important;

    &.text-primary {
      &:hover {
        color: #000 !important;
      }
    }
  }
`

export const LangDropdownMenu = styled(DropdownMenu)`
  &.dropdown-menu {
    left: auto;
    right: 0;
    margin-top: -2px;
    border: 0;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    min-width: 100%;
    padding: 0;

    .dropdown-item {
      padding: 0.625rem 0.75rem;
    }
  }
`

export const FlagImg = styled(Img)`
  cursor: pointer;
`

export const StyledDropdownMenu = styled(DropdownMenu)`
  &.dropdown-menu {
    margin-top: -2px;
    border: 0;
    border-top: 0;
    border-top-left-radius: 0;
    padding: 0.75rem 0 0.3rem;
    min-width: 12rem;

    .dropdown-item {
      padding: 0.625rem 1.5rem;
      font-size: 1rem;

      &:active {
        background-color: #f7f7f9;
        color: #27292a;
      }

      &.dropdown-item-sm {
        font-size: 1rem;
        color: rgba(33, 37, 41, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.4rem 1.5rem;

        .menu-num {
          background: #5fb798;
          border-radius: 4px;
          display: inline-block;
          color: #fff;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
`
