import styled from 'styled-components'
import router from '../store/router'

export const ShowMainBody = styled.div`
  padding: 40px 0%;

  h2 {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 600;
    margin-top: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
  }

  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ContentBox = styled.div`
  padding: 40px 20px;
  border-radius: 4px;
  background: #fff;
  border-bottom: 5px solid #ededee;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ContentBoxBody = styled.div`
  p {
    font-size: 0.875rem;
    margin: 0 0 30px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  a {
    color: #5fb798;
  }
`

export const ContentBoxTitle = styled.div`
  color: #212529;
  font-weight: 600;
  margin-bottom: 40px;
`

export const PlayListBoxExpanded = styled.div`
  border: 1px solid #5fb798;
  padding: 15px;
  font-size: 11px;
  color: #5fb798;
  margin-bottom: 30px;
  position: relative;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 1px solid #5fb798;
    border-left: 1px solid #5fb798;
    background: #fff;
    transform: rotate(45deg);
    position: absolute;
    left: 20px;
    top: -6px;
  }

  label {
    font-size: 11px;
    font-weight: normal;
    margin: 0;
  }

  div {
    border-bottom: 1px solid #ededee;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

export const LeadDescription = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #646669;
  margin-bottom: 40px;
`

export const Gallery = styled.div`
  display: flex;
  margin: 0 -5px 50px;

  .gallery-col {
    padding: 0 5px;
    display: flex;
    flex-direction: column;

    &:first-child {
      max-width: 490px;
    }

    &:last-child {
      max-width: 517px;
    }
  }

  .gallery-row {
    display: flex;
    margin: 0 -5px 10px;
  }

  .gallery-col-inner {
    padding: 0 5px;
  }
`

export const SectionTabsWrapper = styled.div``

export const ImageVideoWrapper = styled.div`
    position: relative;
    margin-bottom: 40px;

    .carousel-arrow {
        height: 50px;
        width: 50px;
        top: 50%;
        transform: translateY(-50%);
        background: #5FB798;
        border-radius: 0;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background: #407b66;
        }

        &.carousel-prev {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
                
            &:before {
                background: url('${router.authority}/images/icons/icon-slider-arrow-left.svg') no-repeat center / 9px;
            }
        }

        &.carousel-next {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            &:before {
                background: url('${router.authority}/images/icons/icon-slider-arrow-right.svg') no-repeat center / 9px;
            }
        }
    }
`

export const VideoPlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  svg {
    font-size: 2rem;
    color: #fff;
    margin-left: 4px;
  }
`

export const PressReviewWrapper = styled.div`
  margin-bottom: 50px;

  p {
    margin: 0;
  }

  a {
    font-weight: 600;
    color: #292b2c;
    text-decoration: none;

    &:hover {
      color: #5fb798;
      text-decoration: underline;
    }
  }

  .press-review-date {
    text-align: right;
    font-size: 0.8rem;
    margin-top: 1rem;
    color: rgba(41, 43, 44, 0.7);
  }
`

export const StyledYellowBadge = styled.div`
  min-width: 120px;
  min-height: 24px;
  background: #ffd336;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  z-index: 1;
  top: 20px;
  transform: ${props => (props.right ? 'rotate(45deg)' : 'rotate(-45deg)')};
  left: ${props => (props.right ? 'auto' : '-30px')};
  right: ${props => (props.right ? '-30px' : 'auto')};
`
