import { API, graphqlOperation } from 'aws-amplify'
import sha256 from 'fast-sha256'

const addShardingSuffix = (data) => {
  const hash = sha256(data.sortKey)

  //shard by 1st 2 chars of sortKey's sha256 hash
  data.partitionKey += '_' + String(hash).substr(0, 2)

  return data
}

const initDefaults = (data) => {
  if (!data.partitionKey) return
  data.partitionKey = data.partitionKey.toLowerCase()

  if (!data.sortKey) return
  data.sortKey = data.sortKey.toLowerCase()

  // if no data {} provided, set {asString: string} from value
  if (data.value && typeof data.data !== 'object') {
    data.data = { asString: data.value }
  }

  // if no value provided but data exists, set value to data.asString
  if (!data.value && data.data) {
    data.value = data.data.asString
  }

  // if data is not encoded as string, stringify it
  if (typeof data.data !== 'string') {
    data.data = JSON.stringify(data.data)
  }

  // increase performance by suffix sharding
  if (data.sharding) {
    data = addShardingSuffix(data)
  }

  delete data.sharding

  return data
}

const initPlayDefaults = (data) => {
  if (!data.partitionKey) return
  data.partitionKey = data.partitionKey.toLowerCase()

  if (!data.sortKey) return
  data.sortKey = data.sortKey.toLowerCase()

  delete data.sharding

  return data
}
const fetch = async (p, field) => {
  p = initDefaults(p)

  return await API.graphql(
    graphqlOperation(
      `query GetRecord($partitionKey: String!, $sortKey: String!) {
        getRecord(partitionKey: $partitionKey,  sortKey: $sortKey) { ${field} } }`,
      p
    )
  )
}

export async function fetchRecord(params) {
  var re = null
  if (params.value) {
    if (params.partialSortKey) {
      re = await fetchByFilterWithoutSortKey(params, 'data')
    } else {
      re = await fetchByFilter(params, 'data')
    }
  } else {
    re = await fetch(params, 'data')
  }

  if (!re) return null
  return re.data.getRecord ? JSON.parse(re.data.getRecord.data) : null
}

export async function fetchHistory(params) {
  var re = null

  re = await fetch(params, 'data')

  if (!re) return null
  return re.data.getHistory ? JSON.parse(re.data.getHistory.data) : null
}

const fetchByFilterWithoutSortKey = async (p, field) => {
  p = initDefaults(p)

  return await API.graphql(
    graphqlOperation(
      `query GetRecord($partitionKey: String!, $sortKey: String!) {
        getRecord(partitionKey: $partitionKey, sortKey: { beginsWith: "play_"},  filter:{value:{match:$value}}) { ${field} } }`,
      p
    )
  )
}

const fetchByFilter = async (p, field) => {
  p = initDefaults(p)

  return await API.graphql(
    graphqlOperation(
      `query GetRecord($partitionKey: String!, $sortKey: String!) {
        getRecord(partitionKey: $partitionKey,  sortKey: $sortKey, filter:{value:{match:$value}}) { ${field} } }`,
      p
    )
  )
}

const fetchListRecords = async (p, field) => {
  p = initDefaults(p)

  var query = ''
  query += 'query ListRecords{'
  query += 'listRecords(partitionKey:"'
  query += p.partitionKey
  query += '",sortKey:{beginsWith:"'
  query += p.sortKey
  query += '"}'
  if (p.filter) {
    if (p.filter !== null) query += ',filter:{' + p.filter + '}'
  }
  if (p.limit) {
    query += ',limit:'
    query += p.limit
  } else {
    query += ',limit:'
    query += '2000'
  }
  if (p.nextToken) {
    query += ', nextToken:'
    if (p.nextToken === null) query += 'null'
    else query += '"' + p.nextToken + '"'
  }
  query += '){ items{ data,value},nextToken  }}'

  return await API.graphql(graphqlOperation(query))
}

const searchRecords = async (p, field) => {
  p = initDefaults(p)

  return await API.graphql(
    graphqlOperation(
      `query searchRecords{
        searchRecords(filter: {record:{match:thomas.lange@culturebooking.com}} ){
          items{
            data
          }
        }
      }`
    )
  )
}

const runQuery = async (query) => {
  return await API.graphql(graphqlOperation(query))
}
export async function executeQuery(query) {
  const re = await runQuery(query)

  return re
}
export async function fetchRecordAsString(params) {
  const re = await fetch(params, 'value')
  if (!re) return null
  return re.data.getRecord ? String(re.data.getRecord.value) : null
}

export async function fetchList(params) {
  const re = await fetchListRecords(params, 'data')
  if (!re) return null
  return re
}

export async function searchRecord(params) {
  const re = await searchRecords(params, 'data')
  if (!re) return null
  return re
}

export async function createRecord(data) {
  const input = initDefaults(data)
  await API.graphql(
    graphqlOperation(
      `mutation _($input: CreateRecordInput!) { createRecord(input: $input) { data } } `,
      { input }
    )
  )
}

export async function createHistory(data) {
  const input = initDefaults(data)

  await API.graphql(
    graphqlOperation(
      `mutation _($input: CreateHistoryInput!) { createHistory(input: $input) { data } } `,
      { input }
    )
  )
}

export async function deleteRecord(data) {
  const input = initDefaults(data)
  await API.graphql(
    graphqlOperation(
      `mutation _($input: DeleteRecordInput!) { deleteRecord(input: $input) { data } } `,
      { input }
    )
  )
}

export async function updateRecord(data) {
  const input = initDefaults(data)
  await API.graphql(
    graphqlOperation(
      `mutation _($input: UpdateRecordInput!) { updateRecord(input: $input) { data } } `,
      { input }
    )
  )
}
