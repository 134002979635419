import React, { useEffect, useRef } from 'react'
import { ModalBody, Label } from '@bootstrap-styled/v4'
import { observer } from 'mobx-react-lite'
import { ConfirmModal } from '../styles/DialogConfirm'
import { FaWindowClose } from 'react-icons/fa'
const DialogConfirm = observer((props) => {
  return (
    <ConfirmModal isOpen='true'>
      <div style={{ textAlign: 'right' }}>
        <FaWindowClose
          style={{
            fontSize: '28px',
            right: '0px',
            cursor: 'pointer',
            marginRight: '2px',
          }}
          onClick={() => (props.setClose ? props.setClose(false) : null)}
        ></FaWindowClose>
      </div>

      <ModalBody>{props.children}</ModalBody>
    </ConfirmModal>
  )
})
export default DialogConfirm
