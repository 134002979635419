import { types as t, flow, applySnapshot, getSnapshot } from 'mobx-state-tree'
import Currency from './currency'
import { fetchRecord, createRecord } from '../graphql'
const Currencies = t
  .model('Currencies', {
    currencies: t.optional(t.array(Currency), []),
  })
  .volatile((self) => ({
    PartitionKey: 'currencies',
    SortKey: 'currencies',
  }))
  .actions((self) => ({
    afterCreate() {
      self.currencies.push({ code: 'EUR', name: 'Euro', rate: 1 })
      self.currencies.push({
        code: 'HRK',
        name: 'Croatian kuna',
        rate: 7.56,
      })
    },
    fetch: flow(function* () {
      const data = {
        partitionKey: self.PartitionKey,
        sortKey: self.SortKey,
      }
      const snapshot = yield fetchRecord(data)
      if (snapshot) {
        applySnapshot(self, snapshot)
      }
    }),
    save() {
      return Promise.resolve(
        createRecord({
          partitionKey: self.PartitionKey,
          sortKey: self.SortKey,
          data: getSnapshot(self),
          value: null,
        })
      )
    },
  }))
  .views((self) => ({
    find(curr) {
      return self.currencies.find((c) => c.code === curr)
    },
  }))

export default t.optional(Currencies, {})
