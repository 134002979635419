import { types, flow, getRoot } from 'mobx-state-tree'
import { fetchRecordAsString } from '../graphql'

const model = {
  label: types.identifier,
  translation: '',
  language: types.string,
  approved: false
}

const actions = t => ({
  afterCreate() {
    if (!t.label) return

    if (t.translation === '') {
      // if no translation provided
      t.translation = t.label

      // try fetching from database
      t.fetch()
    }
  },

  save() {
    getRoot(t).save({
      partitionKey: 'translation_' + t.language,
      sharding: true,
      sortKey: t.label,
      value: t.translation
    })
  },

  set(field, value) {
    if (field === 'translation') {
      while (value.indexOf(':') > -1) {
        value = value.replace(':', ' ')
      }
    }

    if (value === '' || value === null) return
    t[field] = value

    if (field === 'translation') {
      // insert/overwrite in database
      t.save()
    }
  },

  // flow is used to create async actions by using yield inside a generator function (*)
  fetch: flow(function*() {
    const data = {
      partitionKey: 'translation_' + t.language,
      sharding: true,
      sortKey: t.label
    }
    const dbTranslation = yield fetchRecordAsString(data)

    if (dbTranslation) {
      // use translation
      t.set('translation', dbTranslation)
    } else {
      // use label as translation
      t.set('translation', t.label.substring(3))
      // add translation to database
      t.addToDatabase()
    }
  }),

  addToDatabase() {
    if (!t.label) return
    if (!t.translation) return

    try {
      t.save()
    } catch (error) {
      console.error('Failed to set translation', error)
    }
  }
})

const Translation = types.model(model).actions(actions)

export default Translation
