import { types as t } from 'mobx-state-tree'

const Currency = t
  .model('Currency', {
    code: t.maybeNull(t.string),
    name: t.maybeNull(t.string),
    rate: t.maybeNull(t.number),
  })
  .views((self) => ({
    Convert(value) {
      return (value / self.rate).toFixed(2)
    },
    ConvertTo(value) {
      return (value * self.rate).toFixed(2)
    },
  }))

export default t.optional(Currency, {})
