import { types as t, getSnapshot, flow, applySnapshot } from 'mobx-state-tree'
import uuid from 'uuid'
import {
  createRecord,
  fetchRecord,
  executeQuery,
  deleteRecord
} from '../graphql'
import { FaSave } from 'react-icons/fa'

const LinkReference = t
  .model('LinkReference', {
    reference: t.maybeNull(t.string),
    link: t.maybeNull(t.string)
  })
  .actions(self => ({
    set(key, value) {
      self[key] = value
    },
    save() {
      return Promise.resolve(
        createRecord({
          partitionKey: 'link',
          sortKey: 'link ' + self.link,
          data: null,
          value: self.reference
        })
      )
    },
    fetch: flow(function*(link) {
      const data = { partitionKey: 'link', sortKey: 'link ' + self.link }

      const snapshot = yield executeQuery(self.getQuery(link))
      if (snapshot) {
        self.link = link
        self.reference = snapshot.value
      }
    })
  }))
  .views(self => ({
    getQuery(link) {
      return (
        'query{          getRecord(partitionKey: "link" sortKey: "link ' +
        link +
        '"          ) {              value          }      }'
      )
    }
  }))

export default t.optional(LinkReference, {})
