import { types as t, flow, getSnapshot, applySnapshot } from 'mobx-state-tree'
import KeyValueCollection from './keyvalue'
import { fetchRecord, createRecord } from '../graphql'

const Configuration = t.model('Configuration', {
  api:
    'https://uxmc7j1nhc.execute-api.eu-central-1.amazonaws.com/live/pricetracker',
})

export default t.optional(Configuration, {})
