import { types as t, getSnapshot, flow, applySnapshot } from 'mobx-state-tree';
import uuid from 'uuid';
import {
  createRecord,
  fetchRecord,
  executeQuery,
  deleteRecord,
} from '../graphql';
import LinkReference from './linkreference';
import Validation from './validation';
import Configuration from './configuration';
const Item = t
  .model('Item', {
    reference: t.maybeNull(t.string),
    title: t.maybeNull(t.string),
    site: t.maybeNull(t.string),
    link: t.maybeNull(t.string),
    priceOld: t.maybeNull(t.number),
    price: t.maybeNull(t.number),
    manufacturer: t.maybeNull(t.string),
    category: t.maybeNull(t.string),
    stock: t.maybeNull(t.number),
    lastchecked: t.maybeNull(t.string),
    stamp: t.maybeNull(t.Date),
  })
  .volatile((self) => ({
    PartitionKey: 'item',
    Changed: false,
    linkReference: LinkReference,
    validation: Validation.create(),
  }))
  .actions((self) => ({
    afterCreate() {
      if (self.reference == null) self.reference = uuid.v4();
      if (self.price == null) self.price = 0;

      self.validation.setFunc('title', () => {
        if (self.title == null) return 'title is required';
        if (self.title.length === 0) return 'title is required';
        return '';
      });

      self.validation.setFunc('link', () => {
        if (self.link == null) return 'link is required';
        if (self.link.length === 0) return 'link is required';
        if (
          self.link.indexOf('http://') < 0 &&
          self.link.indexOf('https://') < 0
        )
          return 'link is invalid';

        if (self.link.indexOf(self.site) < 0)
          return 'link must be from site ' + self.site;

        return '';
      });

      self.validation.setFunc('site', () => {
        if (self.site == null) return 'site is required';
        if (self.site.length === 0) return 'site is required';
        return '';
      });
    },
    set(key, value) {
      self[key] = value;
      if (key === 'link') self['reference'] = value;
    },
    fetch: flow(function* () {
      const data = {
        partitionKey: self.PartitionKey,
        sortKey: self.SortKey,
        sharding: false,
      };
      const snapshot = yield fetchRecord(data);

      if (snapshot) {
        applySnapshot(self, snapshot);
      }
    }),
    delete() {
      Promise.resolve(
        deleteRecord({
          partitionKey: self.PartitionKey,
          sortKey: self.SortKey,
        })
      );
    },
    async activate(act) {
      var i = 0;
      if (act === true) i = 1;
      var config = Configuration.create();
      fetch(
        config.api +
          '?type=Item&cmd=setActive&active=' +
          i +
          '&reference=' +
          self.reference
      ).then(() => {
        return true;
      });
    },
    fetchBySiteAndReference: flow(function* (site, reference) {
      const query =
        'query      {        listRecords(          partitionKey: "item",          sortKey: { eq: "item [site] [item_reference]" }        ) {          nextToken,            items{ data }        }      }';
      const snapshot = yield executeQuery(
        query.replace('[site]', site).replace('[item_reference]', reference)
      );

      if (snapshot) {
        try {
          applySnapshot(
            self,
            JSON.parse(snapshot.data.listRecords.items[0].data)
          );
        } catch (e) {}
      }
    }),

    save() {
      //  self.site = self.Site

      return Promise.resolve(
        createRecord({
          partitionKey: self.PartitionKey,
          sortKey: self.SortKey,
          sharding: false,
          data: getSnapshot(self),
          value: self.title.toLowerCase(),
        })
      );
    },

    parseSortKey(sortKey) {
      var data = sortKey.split(' ');
      if (data.length > 2) {
        self.set('reference', data[2]);
        self.set('site', data[1]);
      }
    },
  }))
  .views((self) => ({
    get SortKey() {
      return 'item ' + self.site + ' ' + self.reference;
    },
    get(key) {
      return self[key] != null ? self[key] : '';
    },
    get Siteold() {
      if (self.link.trim().length > 0) {
        var l = document.createElement('a');
        l.href = self.link;
        return l.hostname;
      }
    },
    get Price() {
      return self.price == null ? 0 : self.price;
    },
  }));

export default t.optional(Item, {});
